export default {
  messages: {
    uk: {
      'QR-код буде дійсний ще': 'QR-код буде дійсний ще',
      'Оновити QR-код': 'Оновити QR-код',
      'Відскануйте QR-код сканером': 'Відскануйте QR-код сканером',
      'у застосунку Дія': 'у застосунку Дія',
      'QR-код застарів': 'QR-код застарів',
      'Відкрити застосунок Дія': 'Відкрити застосунок Дія',
      'Посилання буде дійсним ще': 'Посилання буде дійсним ще',
      'Оновити посилання': 'Оновити посилання',
      'Термін дії вашого посилання минув': 'Термін дії вашого посилання минув',
      'Перейдіть у застосунок Дія': 'Перейдіть у застосунок Дія',
      'для підтвердження': 'для підтвердження',
      'Підтвердіть документи на авто через Дія.Підпис': 'Підтвердіть документи на авто через Дія.Підпис',
    },
    ru: {
      'QR-код буде дійсний ще': 'QR-код будет действителен еще',
      'Оновити QR-код': 'Обновить QR-код',
      'Відскануйте QR-код сканером': 'Отсканируйте QR-код сканером',
      'у застосунку Дія': 'в приложениии Дія',
      'QR-код застарів': 'QR-код устарел',
      'Відкрити застосунок Дія': 'Открыть приложение Дія',
      'Посилання буде дійсним ще': 'Ссылка будет действительно еще',
      'Оновити посилання': 'Обновить ссылку',
      'Термін дії вашого посилання минув': 'Срок действия вашей ссылки истек',
      'Перейдіть у застосунок Дія': 'Перейдите в приложение Дія',
      'для підтвердження': 'для подтверждения',
      'Підтвердіть документи на авто через Дія.Підпис': 'Подтвердите документы на авто через Дія.Подпись',
    }
  }
}
