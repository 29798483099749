export default {
    messages: {
        uk: {
            'Авторизуватись через Дію': 'Авторизуватись через Дію',
            'Підтвердити власність авто': 'Підтвердити власність авто',
        },
        ru: {
            'Авторизуватись через Дію': 'Авторизироваться через Дію',
            'Підтвердити власність авто': 'Подтвердить собственность авто',
        }
    }
}
