export default {
  auth: [
    {
      id: '0',
      description: 'Відскануйте QR-код',
    },
    {
      id: 'auth-1',
      description: 'Підтвердьте запит на авторизацію через Дія.Підпис',
    },
    {
      id: '2',
      description: 'Зробіть фото для перевірки',
    },
    {
      id: '3',
      description: 'Введіть код для Дія.Підпис',
    },
    {
      id: 'auth-5',
      description: 'Завершіть запит на авторизацію через Дія.Підпис',
    }
  ],
  owner: [
    {
      id: '0',
      description: 'Відскануйте QR-код',
    },
    {
      id: 'owner-1',
      description: 'Підтвердьте відправку копії свідоцтва',
    },
    {
      id: '2',
      description: 'Зробіть фото для перевірки',
    },
    {
      id: '3',
      description: 'Введіть код для Дія.Підпис',
    },
    {
      id: 'owner-5',
      description: 'Підтвердьте відправку копій цифрових документів',
    },
  ]
};
