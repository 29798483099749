export default {
  messages: {
    ru: {
      авторазборка: 'авторазборка',
      СТО: 'СТО',
      автосалон: 'автосалон',
      автомойка: 'автомойка',
      компания: 'компания',
      пакет: 'пакет',
      'Меню личного кабинета': 'Меню личного кабинета',
      'Добавить объявление': 'Добавить объявление',
      'Личный кабинет': 'Личный кабинет',
      'Мои объявления': 'Мои объявления',
      'Личный счет': 'Личный счет',
      'Коммерческие сервисы': 'Коммерческие сервисы',
      Уведомления: 'Уведомления',
      Подписки: 'Подписки',
      Избранное: 'Избранное',
      'Заказы на проверку авто': 'Заказы на проверку авто',
      'Мобильное приложение': 'Мобильное приложение',
      'Товары для авто': 'Товары для авто',
      Настройки: 'Настройки',
      Выход: 'Выход',
      'Продлить пакет': 'Продлить пакет',
      'Не активен': 'Не активен',
      'Продовжити пакет': 'Продлить пакет',
      'Пакет «Авто під замовлення» закінчився': 'Пакет «Авто под заказ» закончился',
      'Послуга «Дзвінки» недоступна': 'Услуга «Звонки» недоступна',
      'Пакет «Автодилер» закінчився': 'Пакет «Автодилер» закончился',
      'Ваша компанія не відображається': 'Ваша компания не отображается',
    },
    uk: {
      авторазборка: 'авторозбірка',
      СТО: 'СТО',
      автосалон: 'автосалон',
      автомойка: 'автомийка',
      компания: 'компанія',
      пакет: 'пакет',
      'Меню личного кабинета': 'Меню особистого кабінету',
      'Добавить объявление': 'Додати оголошення',
      'Личный кабинет': 'Особистий кабінет',
      'Мои объявления': 'Мої оголошення',
      'Личный счет': 'Особистий рахунок',
      'Коммерческие сервисы': 'Комерційні сервіси',
      Уведомления: 'Сповіщення',
      Подписки: 'Підписки',
      Избранное: 'Обране',
      'Заказы на проверку авто': 'Замовлення на перевірку авто',
      'Мобильное приложение': 'Мобільний додаток',
      'Товары для авто': 'Товари для авто',
      Настройки: 'Налаштування',
      Выход: 'Вихід',
      'Продлить пакет': 'Продовжити пакет',
      'Не активен': 'Не активний',
      'Продовжити пакет': 'Продовжити пакет',
      'Пакет «Авто під замовлення» закінчився': 'Пакет «Авто під замовлення» закінчився',
      'Послуга «Дзвінки» недоступна': 'Послуга «Дзвінки» недоступна',
      'Пакет «Автодилер» закінчився': 'Пакет «Автодилер» закінчився',
      'Ваша компанія не відображається': 'Ваша компанія не відображається',
    },
  },
};
