export default {
  messages: {
    uk: {
      'Вкажіть імʼя': 'Вкажіть імʼя',
      'Вкажіть номер телефону': 'Вкажіть номер телефону',
      'Показати ще': 'Показати ще',
      рейтинг: ' рейтинг',
      'Редагувати мою сторінку': 'Редагувати мою сторінку',
    },
    ru: {
      'Вкажіть імʼя': 'Укажите имя',
      'Вкажіть номер телефону': 'Укажите номер телефона',
      'Показати ще': 'Показать еще',
      рейтинг: ' рейтинг',
      'Редагувати мою сторінку': 'Редактировать мою страницу',
    },
  },
};
