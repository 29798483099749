export default {
  messages: {
    uk: {
      'Вітаємо': 'Вітаємо',
      'Авторизуватись через Дію': 'Авторизуватись через Дію',
      'Покупці частіше шукають авто від власників. Вкажіть, що ваша пропозиція саме така': 'Покупці частіше шукають авто від власників. Вкажіть, що ваша пропозиція саме така',
      'Щоб отримати відповідну позначку потрібно': 'Щоб отримати відповідну позначку потрібно',
      'Підтвердити власність авто': 'Підтвердити власність авто',
      'Ви пройшли необхідну перевірку та отримали відповідні позначки на сторінці авто': 'Ви пройшли необхідну перевірку та отримали відповідні позначки на сторінці авто',
      'Позначка "Авторизований через Дію" допоможе': 'Позначка "Авторизований через Дію" допоможе відрізнити продавців, які готові вести свою діяльність відкрито та підтвердили свою особу ',
      'Продаєте своє власне авто? <br> Розкажіть про це!': 'Продаєте своє власне авто? <br> Розкажіть про це!',
    },
    ru: {
      'Вітаємо': 'Поздравляем',
      'Авторизуватись через Дію': 'Авторизироваться через Дію',
      'Покупці частіше шукають авто від власників. Вкажіть, що ваша пропозиція саме така': 'Покупатели чаще ищут автомобили от владельцев. Укажите, что ваше предложение именно таково',
      'Щоб отримати відповідну позначку потрібно': 'Чтобы получить соответствующую отметку нужно',
      'Підтвердити власність авто': 'Подтвердить собственность авто',
      'Ви пройшли необхідну перевірку та отримали відповідні позначки на сторінці авто': 'Вы прошли необходимую проверку и получили соответствующие отметки на странице авто',
      'Позначка "Авторизований через Дію" допоможе': 'Отметка "Авторизований через Дію" поможет отличить продавцов, которые готовы вести свою деятельность открыто и подтвердили личность',
      'Продаєте своє власне авто? <br> Розкажіть про це!': 'Продаете свой собственный автомобиль? Расскажите об этом!',
    }
  }
}
