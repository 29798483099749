export default {
  messages: {
    uk: {
      'Виявилось, що вони від іншого авто. Підтвердіть для цього авто, яке продаєте': 'Виявилось, що вони від іншого авто. Підтвердіть для цього авто, яке продаєте',
      'Підтвердити для цього авто': 'Підтвердити для цього авто',
      'Дякуємо, документи отримали!': 'Дякуємо, документи отримали!',
    },
    ru: {
      'Виявилось, що вони від іншого авто. Підтвердіть для цього авто, яке продаєте': 'Оказалось, что они от другого автомобиля. Подтвердите для этого автомобиль, который продаете',
      'Підтвердити для цього авто': 'Подтвердить для этого авто',
      'Дякуємо, документи отримали!': 'Спасибо, документы получили!',
    }
  }
}
