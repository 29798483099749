export default {
    messages: {
        uk: {
            'Авторизуватись через Дію': 'Авторизуватись через Дію'
        },
        ru: {
            'Авторизуватись через Дію': 'Авторизироваться через Дію'
        }
    }
}
