var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.data.child)?[(_vm.data.vue)?[_c('vue-router-link',{class:[{
            	                        item: true,
            	                        active: _vm.activeMenu === _vm.data.blockName ? true : false,
            	                        hide: !_vm._result(_vm.counters, `${_vm.data.blockName}.count`) && _vm.data.removeEmpty,
            	                        closed: _vm.blockedItem,
                                     }, _vm.data.class],attrs:{"to":{name: _vm.data.blockName},"checked":_vm.checkedMenu === _vm.data.blockName || _vm.data.unfold},nativeOn:{"click":function($event){_vm.toggleMenu(_vm.data.blockName); _vm.toggleMenuBlock(_vm.data.blockName); _vm.emchEndEm({scroll: true, spinner: false});}}},[_c('span',{staticClass:"wrap"},[(_vm.data.icon)?_c('svg',{staticClass:"svg",attrs:{"data-tag":"svg","id":`${_vm.data.icon}-use`}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":`#${_vm.data.icon}`}})]):_vm._e()]),_c('span',{staticClass:"wrap"},[_vm._v("\n                    "+_vm._s(_vm.title)+"\n"),(_vm.blockedItem)?_c('span',{staticClass:"not-active"},[_vm._v("\n                        "+_vm._s(_vm.$t('Не активен'))+"\n                    ")]):_vm._e(),_c('span',{class:{
                            hide: !_vm._result(_vm.counters, `${_vm.data.blockName}.count`),
                            red : _vm.data.alert,
                            blue: !_vm.data.alert,
                            'label-hot': true
                          }},[_vm._v("\n                        "+_vm._s(_vm._result(_vm.counters, `${_vm.data.blockName}.count`))+"\n                    ")])])])]:[_c('a',{class:[{
                    item: true,
                    active: _vm.activeMenu == _vm.data.blockName ? true : false,
                    hide: !_vm._result(_vm.counters, `${_vm.data.blockName}.count`) && _vm.data.removeEmpty,
                    'item-icon': Boolean(_vm.data.proAccount),
               },
               _vm.data.class],attrs:{"href":_vm.data.blockName === 'Chat' ? `javascript:void(0);` : _vm.data.links[_vm.langCode],"target":_vm.data.blockName === 'Chat' ? '' : _vm.data.target},on:{"click":function($event){_vm.toggleMenu(_vm.data.blockName); _vm.emchEndEm({scroll: true, spinner: !_vm.data.target});}}},[_c('span',{staticClass:"wrap"},[(_vm.data.icon)?_c('svg',{staticClass:"svg",attrs:{"data-tag":"svg"}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":`#${_vm.data.icon}`}})]):_vm._e()]),_c('span',{staticClass:"wrap"},[_vm._v("\n                        "+_vm._s(_vm.title)+"\n"),_c('span',{class:{
                                hide: !_vm._result(_vm.counters, `${_vm.data.blockName}.count`),
                                red : _vm.data.alert,
                                blue: !_vm.data.alert,
                                'label-hot': true
                              }},[_vm._v("\n                            "+_vm._s(_vm._result(_vm.counters, `${_vm.data.blockName}.count`))+"\n                        ")])])])]]:(_vm.data.child)?[_c('input',{ref:"menuBlocks",staticClass:"checkbox-pointer hide",attrs:{"type":"checkbox","id":_vm.data.blockName},domProps:{"checked":_vm.checkedMenu === _vm.data.blockName || _vm.unfold},on:{"click":function($event){return _vm.toggleMenuBlock(_vm.data.blockName)}}}),_c('label',{staticClass:"item",class:{[_vm.data.class]: _vm.data.class, closed: _vm.blockedItem},attrs:{"for":_vm.data.blockName,"tabindex":_vm.data.index}},[_c('span',{staticClass:"wrap"},[(_vm.data.icon)?_c('svg',{staticClass:"svg",attrs:{"data-tag":"svg","id":`${_vm.data.icon}-use`}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":`#${_vm.data.icon}`}})]):_vm._e()]),(_vm.data.link)?_c('vue-router-link',{staticClass:"unlink text wrap",attrs:{"to":{name: _vm.data.blockName}},nativeOn:{"click":function($event){_vm.toggleMenu(_vm.data.blockName); _vm.toggleMenuBlock(_vm.data.blockName); _vm.emchEndEm({scroll: true, spinner: false});}}},[_vm._v("\n                "+_vm._s(_vm.title)+"\n                "),(_vm.blockedItem)?_c('span',{staticClass:"not-active"},[_vm._v("\n                    Не активна\n                ")]):_vm._e(),_c('span',{staticClass:"label-hot",class:{
                        hide: (_vm.checkedMenu === _vm.data.blockName || _vm.unfold) || !_vm._result(_vm.counters, `${_vm.data.blockName}.count`),
                        blue: !_vm.data.alert
                      }},[_vm._v("\n                    "+_vm._s(_vm._result(_vm.counters, `${_vm.data.blockName}.count`))+"\n                ")])]):_c('span',{staticClass:"wrap"},[_vm._v("\n                "+_vm._s(_vm.title)+"\n                "),(_vm.blockedItem)?_c('span',{staticClass:"not-active"},[_vm._v("\n                    Не активна\n                ")]):_vm._e(),_c('span',{staticClass:"label-hot",class:{
                        hide: (_vm.checkedMenu === _vm.data.blockName || _vm.unfold) || !_vm._result(_vm.counters, `${_vm.data.blockName}.count`),
                        blue: !_vm.data.alert
                      }},[_vm._v("\n                    "+_vm._s(_vm._result(_vm.counters, `${_vm.data.blockName}.count`))+"\n                ")])])],1),(!_vm.blockedItem)?_c('span',{staticClass:"secondary"},[_vm._l((_vm.data.child),function(menuChildItem){return [(menuChildItem.vue)?[_c('vue-router-link',{ref:menuChildItem.blockName,refInFor:true,staticClass:"item",class:{
                                                    hide: !_vm._result(_vm.counters, `${menuChildItem.blockName}.count`) && menuChildItem.removeEmpty,
                                                    item: true,
                                                    active: _vm.activeMenu === menuChildItem.blockName ? true : false
                                                 },attrs:{"to":{name: menuChildItem.blockName}},nativeOn:{"click":function($event){_vm.toggleMenu(menuChildItem.blockName, 'child'); _vm.emchEndEm({scroll: true, spinner: false});}}},[_c('span',{staticClass:"wrap"}),_c('span',{staticClass:"wrap"},[_vm._v("\n                                "+_vm._s(menuChildItem.title[_vm.langId===2 ? 'ru': 'uk'])+"\n"),_c('span',{class:{
                                        hide: !_vm._result(_vm.counters, `${menuChildItem.blockName}.count`),
                                        red : menuChildItem.alert,
                                        blue: !menuChildItem.alert,
                                        'label-hot': true
                                      }},[_vm._v("\n                                    "+_vm._s(_vm._result(_vm.counters, `${menuChildItem.blockName}.count`))+"\n                                ")])])])]:[_c('a',{ref:menuChildItem.blockName,refInFor:true,class:[{
                                        hide: !_vm._result(_vm.counters, `${menuChildItem.blockName}.count`) && menuChildItem.removeEmpty,
                                        item: true,
                                        active: _vm.activeMenu === menuChildItem.blockName ? true : false,
                                        'item-icon': Boolean(menuChildItem.proAccount),
                                    },
                                    menuChildItem.class
                           ],attrs:{"href":_vm.getChildrenItemLink(menuChildItem.links),"target":menuChildItem.target},on:{"click":function($event){_vm.toggleMenu(menuChildItem.blockName, 'child'); _vm.emchEndEm({scroll: true, spinner: menuChildItem.target!=='_blank' ? true : false });}}},[_c('span',{staticClass:"wrap"}),_c('span',{staticClass:"wrap"},[_vm._v("\n                                "+_vm._s(menuChildItem.title[_vm.langId===2 ? 'ru': 'uk'])+"\n"),_c('span',{class:{
                                        hide: !_vm._result(_vm.counters, `${menuChildItem.blockName}.count`),
                                        red : menuChildItem.alert,
                                        blue: !menuChildItem.alert,
                                        'label-hot': true
                                      }},[_vm._v("\n                                    "+_vm._s(_vm._result(_vm.counters, `${menuChildItem.blockName}.count`))+"\n                                ")])])])]]})],2):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }