import { mapGetters, mapActions } from 'vuex';
import svgSprite from './icons.vue';

import settings from './settings';
import ProTurbo from './components/icons/ProTurbo/index.vue';

export default {
  name: 'ProPanel',
  components: {
    'pro-turbo-icon': ProTurbo,
    svgSprite,
  },
  data() {
    return {
      buttonSetting: settings.buttons,
    };
  },
  computed: {
    ...mapGetters({
      leftBarData: 'proAccount/getProAccountLeftBarData',
    }),
    hasBorder() {
      return this.leftBarData?.hasBorder;
    },
    notice() {
      return this.leftBarData?.notice;
    },
  },
  methods: {
    ...mapActions({
      fetchProAccountLeftBar: 'proAccount/fetchProAccountLeftBar',
    }),
    async handleChangeStage({ path, location }) {
      if (path) this.$router.push(`${this.langPrefix}${path}`);
      else if (location) window.open(location, '_blank');
    },
  },
  async mounted() {
    await this.fetchProAccountLeftBar();
  },
};
