import { mapGetters, mapActions } from 'vuex';
import HeadLeftBar from './HeadLeftBar/index.vue';
import OneMenuItem from './OneMenuItem/index.vue';
import PersonalManager from './PersonalManager/index.vue';
import ProPanel from './ProPanel/index.vue';
import UserInfo from './UserInfo/index.vue';

const { unset, get, deleted } = require('../../../../helpers/cookie');

export default {
  name: 'LeftBar',
  metaInfo() {
    return {
      bodyAttrs: {
        class: this.menuActive ? 'overflow-0' : '',
      },
    };
  },
  i18n: require('./i18n').default,
  mixins: [
    require('../../../../mixins/exitLogger'),
  ],
  components: {
    'head-left-bar': HeadLeftBar,
    'one-menu-item': OneMenuItem,
    'personal-manager': PersonalManager,
    'pro-panel': ProPanel,
    'user-info': UserInfo,
  },
  data() {
    return {
      menuClass: '',
      activeMenu: '',
      checkedMenu: '',
      localization: {
        ru: '',
        uk: '',
      },
      isMenuHidden: true,
      timer: null,
      timerCounter: 0,
    };
  },
  mounted() {
    if (!Object.keys(this.leftBar).length) {
      this.setLeftBar();
    }
    if (!this.segment) {
      this.setSegment();
    }
    this.timer = setInterval(() => {
      this.timerCounter += 1;
      this.setLeftBar();
    }, 3000);
  },
  methods: {
    ...mapActions({
      setLeftBar: 'cabinet/leftBar/leftBar',
      setSegment: 'cabinet/loadSegment',
    }),
    userData() {
      return this.getUserData.id || {};
    },
    toggleMenu(blockName, isChild) {
      this.activeMenu = blockName;
      if (!isChild && !this.isMobile) {
        this.checkedMenu = '';
      }
      if (this.isMobile) {
        this.menuClass ? this.menuClass = '' : this.menuClass = 'm-open';
        this.isMenuHidden = !this.isMenuHidden;
      }
    },
    openMobileMenu() {
      this.gaEvent('Cabinet', this.menuClass ? 'Navbar_Close' : 'Cabinet_Navbar', 'Cabinet_Navbar');
      this.menuClass ? this.menuClass = '' : this.menuClass = 'm-open';
      this.isMenuHidden = !this.isMenuHidden;
    },
    toggleMenuBlock(blockName) {
      this.checkedMenu = this.checkedMenu === blockName ? '' : blockName;
    },
    cl(someUrl) {
      window.location.href = someUrl;
    },
    emchEndEm({ scroll, spinner }) {
      this.$emit('emchEndEm', { scroll, spinner });
    },
    logout() {
      localStorage.removeItem('ria_auth_ua_data');
      deleted('PSP_ID', '/', '.ria.com', '');
      deleted('PSP_ID', '/', '.ria.com', 'Secure; SameSite=None; Partitioned;');
      unset('PHPSESSID');
      unset('jwt');
      this.loggerExit(10);
      window.location = `/?exit=yes&sourceId=10&screentype=${this.isMobile ? 2 : 1}`;
    },
  },
  created() {
    if (this.segment && this.segment === 'b2cAutoSalon') {
      this.activeMenu = this.$route.meta.name_b2cAutoSalon;
      this.checkedMenu = this.$route.meta.parentName_b2cAutoSalon;
    } else {
      this.activeMenu = this.$route.name;
      this.checkedMenu = this.$route.meta.parentName;
    }
  },
  watch: {
    checkedMenu(n, o) {
      console.log({ n, o });
    },
    $route(to) {
      this.activeMenu = to.name;
      this.checkedMenu = to.meta.parentName;
    },
    timerCounter() {
      if (Object.keys(this.leftBar).length) {
        clearInterval(this.timer);
      }
    },
  },
  computed: {
    ...mapGetters({
      leftBar: 'cabinet/leftBar/leftBar',
      counters: 'cabinet/leftBar/counters',
      packagesData: 'cabinet/userInfo/packagesData',
      langId: 'lang/id',
      segment: 'cabinet/segment',
      isWebView: 'shared/device/isWebView',
      userInfo: 'cabinet/userInfo/userData',
      getProSellerPageData: 'proAccount/getProSellerPageData',
    }),
    menuActive() {
      return this.isMobile && !this.isMenuHidden;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
