import translate from './i18n';
import { mapGetters, mapMutations } from 'vuex';
import { SET_DIIA_SHARE_ADV_ID, } from "../../../../../store/Common/modules/auth/modules/diia/mutations/mutations-types";

export default {
  name: 'Help',
  data() {
    return {
      isConfirmedCar: 0,
      advIdFromUrl: null,
    };
  },
  computed: {
    ...mapGetters('Common/auth/diia', {
      authDiiaStatus: 'status',
      statusConfirmedCar: 'statusConfirmedCar',
      advId: 'advId',
    }),
    src(){
      return `https://cdn.riastatic.com/docs/pictures/common/1/${this.advId ? '181/18149/18149' : '186/18624/18624'}.png`
    },
  },
  methods: {
    ...mapMutations('Common/auth/diia', {
      setAdvId: SET_DIIA_SHARE_ADV_ID,
    }),
    extractAdIdFromParam(url) {
      try {
        const urlParams = new URLSearchParams(new URL(url).search);
        const adId = urlParams.get('advId');
        if (adId) this.setAdvId(adId);
      } catch (error) {
        console.error('Error parsing URL:', error);
      }
    },
    async getConfirmedOwner() {
      if (this.advId) {
        this.isConfirmedCar = this.statusConfirmedCar && this.statusConfirmedCar.includes(Number(this.advId));
        let listingId = this.$route.query?.advId || '';
        if (this.isConfirmedCar && listingId) {
          window.location.href = this.langPrefix + `/auto___${listingId}.html`;
        }
      }
      this.$emit('updateTitle', this.advId ? this.$t('Продаєте своє власне авто? <br> Розкажіть про це!') : '');
    },
    viewManual(value) {
      this.$emit('next', value);
      this._slonik({
        event_id: 221,
        current_point_action: value === 'auth' ? 'autorithation_diia' : 'share_diia_document',
        previous_point_action: 'add_diia',
      })
    },
  },
  mounted() {
    const currentUrl = window.location.href;
    this.extractAdIdFromParam(currentUrl);
    this.getConfirmedOwner();
  },
  i18n: {
    ...translate,
  },
}
