export default {
  messages: {
    uk: {
      'Щось пішло не так': 'Щось пішло не так',
      'На жаль, ми не отримали підтвердження в застосунку Дія': 'На жаль, ми не отримали підтвердження в застосунку Дія',
      'Спробувати ще раз': 'Спробувати ще раз',
      'Підтвердіть документи на авто через Дія.Підпис': 'Підтвердіть документи на авто через Дія.Підпис',
    },
    ru: {
      'Щось пішло не так': 'Что-то пошло не так',
      'На жаль, ми не отримали підтвердження в застосунку Дія': 'К сожалению, мы не получили подтверждения в приложении Дія',
      'Спробувати ще раз': 'Попробовать еще раз',
      'Підтвердіть документи на авто через Дія.Підпис': 'Подтвердите документы на авто через Дія.Подпись',
    }
  }
}
