import translate from './i18n';
import steps from "./steps";
import {mapGetters} from 'vuex';

export default {
  name: 'Instruction',
  data() {
    return {
      currentIndex: 0,
      touch: {x: 0},
      steps,
      imgSrc: 'https://css.riastatic.com/images/demo/cabinet/authorized-through-diia/img-'
    }
  },
  computed: {
    ...mapGetters('Common/auth/diia', {
      typeCheck: 'typeCheck',
    }),
    typeCheckAuth() {
      return this.typeCheck === 'auth';
    },
    titleNotice() {
      return this.typeCheckAuth ? 'Авторизований через Дію' : 'Власник авто. Підтверджено через Дію';
    },
  },
  methods: {
    prev() {
      if (this.currentIndex !== 0) {
        this.currentIndex -= 1;
      } else {
        this.currentIndex = this.steps[this.typeCheck].length - 1;
      }
    },
    next() {
      if (this.currentIndex < this.steps[this.typeCheck].length - 1) {
        this.currentIndex += 1;
      } else {
        this.currentIndex = 0;
      }

    },
    touchstart(event) {
      const {changedTouches: {0: {pageX: x} = {}} = {}} = event;
      this.touch.x = x;
    },
    touchend(event) {
      const {changedTouches: {0: {pageX: x} = {}} = {}} = event;
      if (x - this.touch.x > 50) {
        this.prev();
      }
      if (this.touch.x - x > 50) {
        this.next();
      }
    },
    confirmDiiaAccount() {
      this.$emit('next');
      this._slonik({
        event_id: 221,
        current_point_action: this.typeCheckAuth ? 'autorithation' : 'share_diia',
        previous_point_action: this.typeCheckAuth ? 'autorithation_diia' : 'share_diia_document',
      })
    }
  },
  mounted() {
    this.$emit('updateTitle', this.typeCheck === 'owner' ? this.$t('Як пошерити техпаспорт через Дію?') : '');
  },
  i18n: {
    ...translate,
  },
};
