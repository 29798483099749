import { mapGetters } from 'vuex';

import i18n from '../i18n';

export default {
  name: 'HeadLeftBar',
  i18n,
  components: {
    'expired-auto-dealer': require('../../../components/ExpiredAutoDealers/index.vue').default,
  },
  computed: {
    ...mapGetters({
      packagesData: 'cabinet/userInfo/packagesData',
      segment: 'cabinet/segment',
      expiredAD: 'cabinet/getExpiredAutoDealer',
    }),
    expired() {
      return this?.expiredAD || {};
    },
    showExpired() {
      return Boolean(this?.expiredAD?.status);
    },
    titleExp() {
      if (this.expired.isDealer) {
        return this.$t('Пакет «Автодилер» закінчився');
      } if (this.expired.isCaller) {
        return this.$t('Послуга «Дзвінки» недоступна');
      }
      return this.$t('Пакет «Авто під замовлення» закінчився');
    },
    isAutoSalon() {
      return this.segment === 'b2cAutoSalon';
    },
  },
};
