const MINUTE = 60;
const MIN_3 = MINUTE * 3;

import {mapGetters, mapActions} from 'vuex';

import translate from './i18n';

export default {
  name: 'QRCode',
  data() {
    return {
      invalidAt: 0,
      expired: false,
      time: {
        min: '00',
        sec: '00'
      }
    }
  },
  computed: {
    ...mapGetters('Common/auth/diia', {
      authDiiaLink: 'getAuthDiiaLink',
      token: 'token',
      diiaShareLink: 'getDiiaShareLink',
      diiaShareRequestId: 'getDiiaShareRequestId',
      typeCheck: 'typeCheck',
      advId: 'advId',
    }),
    typeCheckAuth() {
      return this.typeCheck === 'auth';
    },
    diiaLink() {
      return this.typeCheckAuth ? this.authDiiaLink : this.diiaShareLink
    },
    src() {
      return 'https://qrcode.ria.com/?text=' + decodeURIComponent(this.diiaLink);
    },
  },
  methods: {
    ...mapActions('Common/auth/diia', {
      fetchAuthDiiaLink: 'fetchDiiaAuthLink',
      fetchConfirmationFromDiia: 'fetchConfirmationFromDiia',
      fetchShareDiiaLink: 'fetchDiiaShareLink',
      fetchConfirmationShareFromDiia: 'fetchConfirmationShareFromDiia',
    }),
    async start() {
      if (this.typeCheckAuth) {
        await this.fetchAuthDiiaLink();
        this.fetchConfirmationFromDiia(this.token);
      } else {
        await this.fetchShareDiiaLink();
        this.fetchConfirmationShareFromDiia({requestId: this.diiaShareRequestId, advId: this.advId});
      }
      this.timer();
    },
    getTimestamp() {
      return (Date.now() / 1000);
    },
    timer() {
      this.expired = false;
      this.time.min = this.humanizeNum(3);
      this.time.sec = this.humanizeNum(0);

      const {getTimestamp} = this;

      this.invalidAt = getTimestamp() + MIN_3;

      const interval = setInterval(() => {
        const now = getTimestamp();

        if (now > this.invalidAt) {
          this.expired = true;
          clearInterval(interval);
          return;
        }
        const timeLeft = this.invalidAt - now;

        const sec = Math.floor(timeLeft % MINUTE);

        this.time.sec = this.humanizeNum(sec);
        this.time.min = this.humanizeNum(Math.floor((timeLeft - sec) / MINUTE));
      }, 1000);
    },
    humanizeNum(num) {
      const str = String(num);
      return str.length > 1 ? str : `0${num}`;
    },
    clickLogger() {
      this._slonik({
        event_id: 221,
        current_point_action: this.typeCheckAuth ? 'autorithation' : 'share_diia',
        previous_point_action: 'link_diia',
      })
    },
  },
  mounted() {
    this.start();
    this.$emit('updateTitle', this.typeCheck === 'owner' ? this.$t('Підтвердіть документи на авто через Дія.Підпис') : '');
  },
  i18n: {
    ...translate
  }
}
