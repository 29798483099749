import translate from './i18n';
import { mapMutations } from 'vuex';
import { SET_DIIA_STAGE_INDEX } from "../../../../../store/Common/modules/auth/modules/diia/mutations/mutations-types";

export default {
  name: 'SharedAnotherCar',
  methods: {
    ...mapMutations('Common/auth/diia', {
      setIndex: SET_DIIA_STAGE_INDEX,
    }),
    tryAgain() {
      this.setIndex(2);
    }
  },
  mounted() {
    this.$emit('updateTitle', this.$t('Дякуємо, документи отримали!'));
  },
  i18n: {
    ...translate
  }
}
