export default {
	messages: {
		ru: {
			'Получить': 'Получить',
			'-30% на публикации до 13 июня!': '-30% на публикации до 13 июня!',
			'Пополните Личный счет': 'Пополните Личный счет',
			'от': 'от',
			'Последний день большой распродажи!' : 'Последний день большой распродажи!',
			'14-21 июня - большая распродажа!' : '14-21 июня - большая распродажа!',
			'Все, что вы не публиковали раньше — публикуйте сейчас': 'Все, что вы не публиковали раньше — публикуйте сейчас',
			'с выгодой -45%!': 'с выгодой -45%!',
			'Добавьте авто с VIN-кодом, которое размещаете на AUTO.RIA впервые, и удачной продажи — выгоды уже ваши!': 'Добавьте авто с VIN-кодом, которое размещаете на AUTO.RIA впервые, и удачной продажи — выгоды уже ваши!',
			'Добавить авто': 'Добавить авто',
			'Опублікувати': 'Опубликовать',
			'Безкоштовна публікація': 'Бесплатная публикация',
			'Вкажіть VIN-код і виставляйте на продаж': 'Укажите VIN-код и выставляйте на продажу',
		  	'Допоможемо з продажем': 'Поможем с продажей',
			'Поповніть Особистий рахунок': 'Пополните Личный счет',
			'травня': 'мая',
			'включно': 'включительно',
			'і': 'и',
			'отримайте': 'получите',
			'вигоди': 'выгоды',
			'днів': 'дней',
			'Поповнити': 'Пополнить',
		},
		uk: {
			'Получить': 'Отримати',
			'-30% на публикации до 13 июня!': '-30% на публікації до 13 червня!',
			'Пополните Личный счет': 'Поповніть Особистий рахунок',
			'от': 'від',
			'Последний день большой распродажи!' : 'Останній день великого розпродажу!',
			'14-21 июня - большая распродажа!': '14-21 червня — великий розпродаж!',
			'Все, что вы не публиковали раньше — публикуйте сейчас': 'Все, що ви не публікували раніше — публікуйте зараз',
			'с выгодой -45%!': 'із вигодою -45%! ',
			'Добавьте авто с VIN-кодом, которое размещаете на AUTO.RIA впервые, и удачной продажи — выгоды уже ваши!': 'Додайте авто з VIN-кодом, яке розміщуєте на AUTO.RIA вперше, і вдалого продажу — вигоди вже ваші!',
			'Добавить авто': 'Додати авто',
			'Опублікувати': 'Опублікувати',
			'Безкоштовна публікація': 'Безкоштовна публікація',
			'Вкажіть VIN-код і виставляйте на продаж': 'Вкажіть VIN-код і виставляйте на продаж',
		  	'Допоможемо з продажем': 'Допоможемо з продажем',
		  	'Поповніть Особистий рахунок': 'Поповніть Особистий рахунок',
		  	'травня': 'травня',
		  	'включно': 'включно',
		  	'і': 'і',
		  	'отримайте': 'отримайте',
		  	'вигоди': 'вигоди',
		  	'днів': 'днів',
		  	'Поповнити': 'Поповнити',
		}
	}
};
