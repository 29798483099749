import { mapActions, mapGetters } from 'vuex';
import translate from './i18n';

export default {
  name: 'UserInfo',
  components: {
    'auth-by-diia-flag': require('./components/AuthByDiiaFlag/index.vue').default,
  },
  computed: {
    ...mapGetters({
      userData: 'cabinet/userInfo/userData',
      balance: 'cabinet/balance/balance',
      segment: 'cabinet/segment',
      getProSellerPageData: 'proAccount/getProSellerPageData',
    }),
    userName() {
      return this.userData?.name || this.$t('Вкажіть імʼя');
    },
    proAccountUrl() {
      return this.getProSellerPageData?.active && `${this.langPrefix}/pro/seller-${this.getProSellerPageData?.data?.userId}.html`;
    },
    userPhoto() {
      const defaultAvatar = 'https://img6.auto.ria.com/images/nophoto/avatar-50x50.png';
      return this.userData.avatar
        ? this.userData.avatar
        : defaultAvatar;
    },
    userPhones() {
      return this.userData?.phones || [];
    },
    userId() {
      return this.userData?.id || '';
    },
    userKarma() {
      return this.userData?.karma || 0;
    },
  },
  methods: {
    ...mapActions({
      setBalance: 'cabinet/balance/balance',
      bindUser: 'Common/auth/diia/bindUser',
    }),
  },
  init({ store, context }) {
    let cookies = {};
    const diiaToken = context?.request?.query?.['diia-token'];

    if (context) {
      cookies = context.request.headers.cookie;
    }

    return Promise
      .all([
        store.dispatch('cabinet/content/content', cookies),
        store.dispatch('cabinet/userInfo/userInfo', cookies),
        store.dispatch('cabinet/balance/balance', cookies),
      ])
      .then(() => store.dispatch('Common/auth/diia/fetchUserAuthDiiaStatus'))
      .then(({ binded } = {}) => {
        if (binded && diiaToken) {
          return store.dispatch('Common/auth/diia/bindUser', diiaToken);
        }
      });
  },
  async mounted() {
    this.setBalance();

    if (this.$route.name === 'CabinetMainDiia') {
      try {
        await this.bindUser(this?.$route?.query?.['diia-token']);
      } catch (error) {
        console.error(error);
      }
      this.router.push('/cabinet');
    }
  },
  i18n: {
    ...translate,
  },
};
