import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OneMenuItem',
  props: ['data', 'activeMenu', 'checkedMenu'],
  data() {
    return {
      unfold: false,
    };
  },
  created() {
    if (this.data.unfold) {
      this.unfold = true;
    }
    axios(`/cabinet/api/left_bar/${this.data.blockName}`)
      .then((resp) => {
        this.pushCounters(resp.data);
      }).catch((e) => {
        console.error('/cabinet/api/left_bar/', e.message);
      });
  },
  computed: {
    ...mapGetters({
      counters: 'cabinet/leftBar/counters',
      expiredAutoDealer: 'cabinet/getExpiredAutoDealer',
    }),
    title() {
      return this.data.title?.[this.langId === 2 ? 'ru' : 'uk'];
    },
    blockedItem() {
      return this._result(this.data, 'inactive');
    },
  },
  methods: {
    ...mapActions({
      pushCounters: 'cabinet/leftBar/pushCounters',
    }),
    toggleMenuBlock(blockName) {
      // за цей костиль благодаріть Натольну
      // там[в унівєрі] таке ніколи не називали костилями) просто оператори умов) (с)
      if (this.unfold && blockName === 'MyAdvertisements') {
        blockName = '';
      }
      this.$emit('onToggleMenuBlock', blockName);
    },
    toggleMenu(blockName, isChild) {
      if (blockName === 'Chat') {
        if (window.riaChat) {
          window.riaChat.showChat();
        }
      }

      this.$emit('onToggleMenu', blockName, isChild);
    },
    emchEndEm({ scroll, spinner }) {
      this.$emit('emchEndEm', { scroll, spinner });
    },
    getChildrenItemLink(links) {
      return links?.[this.langId === 2 ? 'ru' : 'uk'] || `${this.langPrefix}/cabinet`;
    },
  },
  watch: {
    checkedMenu() {
      // за цей костиль благодаріть Натольну
      // [для того щоб меню почало працювати, як зазвичай,
      // а не було завжди відкрите, якесь із меню]
      this.unfold = false;
    },
  },
};
