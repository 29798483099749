export default {
	messages: {
		ru: {
			'Укажите имя': 'Укажите имя',
			'Укажите номер телефона': 'Укажите номер телефона',
			'Ещё': 'Ещё',
			'Ваш рейтинг пользователя': 'Ваш рейтинг пользователя',
			'Личный счет': 'Личный счет',
			'Пополнить': 'Пополнить',
			'Счет продвижения': 'Счет продвижения',
			'Публикаций': 'Публикаций',
			'Доступны до': 'Доступны до',
			'Начислятся': 'Начислятся',
			'Получить бесплатно': 'Получить бесплатно',
			'Купить публикации': 'Купить публикации'
		},
		uk: {
			'Укажите имя': 'Вкажіть імʼя',
			'Укажите номер телефона': 'Вкажіть номер телефону',
			'Ещё': 'Ще',
			'Ваш рейтинг пользователя': 'Ваш рейтинг користувача',
			'Личный счет': 'Особистий рахунок',
			'Пополнить': 'Поповнити',
			'Счет продвижения': 'Промо-рахунок',
			'Публикаций': 'Публікацій',
			'Доступны до': 'Доступні до',
			'Начислятся': 'Нарахуються',
			'Получить бесплатно': 'Отримати безкоштовно',
			'Купить публикации': 'Придбати публікації'
		}
	}
};
