export default {
  messages: {
    uk: {
      'Авторизуватись через Дію': 'Авторизуватись через Дію',
      'Підтвердити власність авто': 'Підтвердити власність авто',
      'Відскануйте QR-код': 'Відскануйте QR-код',
      'Активуйте Дія.Підпис': 'Активуйте Дія.Підпис',
      'Зробіть фото для перевірки': 'Зробіть фото для перевірки. Дотримуйтесь підказок в нижній частині екрану.',
      'Введіть код для Дія.Підпис': 'Введіть код для Дія.Підпис',
      'Підтвердьте відправку копії свідоцтва': 'Підтвердьте відправку копії свідоцтва про реєстрацію транспортного засобу',
      'Завершіть запит на авторизацію через Дія.Підпис': 'Завершіть запит на авторизацію через Дія.Підпис',
      'Підтвердьте запит на копії цифрових документів': 'Підтвердьте запит на копії цифрових документів',
      'Надішліть копію техпаспорта через застосунок Дія': 'Надішліть копію техпаспорта через застосунок Дія. Так ви демонструєте покупцям, що ваша особа підтверджена, а діяльність прозора',
      'Як пошерити техпаспорт через Дію?': 'Як пошерити техпаспорт через Дію?',
      'Після авторизації через Дію ви отримаєте': 'Після авторизації через Дію ви отримаєте відповідну позначку в блоці даних про вас.',
      'Підтвердьте відправку копій цифрових документів': 'Підтвердьте відправку копій цифрових документів',
    },
    ru: {
      'Авторизуватись через Дію': 'Авторизироваться через Дію',
      'Підтвердити власність авто': 'Подтвердить собственность авто',
      'Відскануйте QR-код': 'Отсканируйте QR-код',
      'Активуйте Дія.Підпис': 'Активируйте Дія.Підпис',
      'Зробіть фото для перевірки': 'Сделайте фото для проверок. Следите за подсказками в нижней части экрана.',
      'Введіть код для Дія.Підпис': 'Введите код для Дія.Підпис',
      'Підтвердьте відправку копії свідоцтва': 'Подтвердите отправку копии свидетельства о регистрации транспортного средства',
      'Завершіть запит на авторизацію через Дія.Підпис': 'Завершите запрос на авторизацию через Дія.Підпис',
      'Підтвердьте запит на копії цифрових документів': 'Подтвердите запрос на копию цифровых документов',
      'Надішліть копію техпаспорта через застосунок Дія': 'Отправьте копию техпаспорта через приложение Дію. Так вы демонстрируете покупателям, что ваше лицо подтверждено, а деятельность прозрачна',
      'Як пошерити техпаспорт через Дію?': 'Как пошерить техпаспорт через Дію?',
      'Після авторизації через Дію ви отримаєте': 'После авторизации через Дію вы получите соответствующую отметку в блоке данных о вас.',
      'Підтвердьте відправку копій цифрових документів': 'Подтвердите отправку копий цифровых документов',
    }
  }
}
