const mapSegmentCabinet = {
	'b2c': 1,
	'smb': 2,
	'c2c': 3
};

const mapFullSegmentCabinet = {
	'b2cAutoDealer': 1,
	'b2cAutoSalon': 2,
	'b2cAutoSale': 3,
	'b2cAutoOrder_calls': 4,
	'b2cAutoDisassemble': 5,
	'smb0': 6,
	'smb1-2': 7,
	'smb3+': 8,
	'c2c': 9,
	'c2cNoAds': 10,
	'b2cAutoOrder_calls_exp': 11,
	'b2cAutoDealer_exp': 12,
	'b2cAutoOrder': 13,
	'b2cAutoOrder_exp': 14,
};

module.exports = function (segmentCabinet, needFullSegment = false) {
	let segmentSlon = 0;
	try {
		if (needFullSegment) {
			segmentSlon = mapFullSegmentCabinet[segmentCabinet];
		} else {
			let segment = typeof segmentCabinet === 'string' ? segmentCabinet.slice(0, 3) : '';
			segmentSlon = mapSegmentCabinet[segment];
		}
	} catch (e) {}

	return segmentSlon || 0;
};
