export default {
    buttons: {
        'pro-active': {
            btnClass: 'btn-green flexion jc-start',
            iconId: '#svg-pro',
            iconClass: 'svg-pro green'
        },
        'pro-disabled': {
            btnClass: 'button button--green flexion',
            iconId: '#svg-pro',
            iconClass: 'svg-pro white icon'
        },
        'blocked': {
            btnClass: 'btn-blue link flexion mb-10',
            iconId: '#svg-lock-open',
            iconClass: 'svg'
        },
        'outline': {
            btnClass: 'btn-blue flexion',
            iconId: null,
            iconClass: null
        },
        'link': {
            btnClass: 'unlink not-btn',
            iconId: null,
            iconClass: null
        },
        'pro-v2': {
            btnClass: 'button button--green flexion mb-10',
            iconId: null,
            iconClass: null,
        },
        'seller-pro-v2': {
            btnClass: 'btn-blue link flexion',
            iconId: null,
            iconClass: null,
        },
    }
}
