import {clearURL, formPathByIdClickElement, formDescriptionClickElement} from '../helpers/userNavigation';

module.exports = {
	data() {
		return {
			prevUrl: '',
			prevOldUrl: '',
			prevPathIds: '',
			prevPointAction: '',
			prevEvent: {x: 0, y: 0},
			trackTag: ['A', 'SPAN', 'SELECT', 'INPUT', 'IMG', '', 'use', 'STRONG', 'BUTTON', 'svg', 'path'],
		};
	},
	mounted() {
		this.prevUrl = document.referrer ? document.referrer : '';
		this.userNavigationSendToSlon();

	},
	methods: {
		formDataEventUserNavigation(url, prevUrl, point, prevPoint, pathIds = '') {
			return {
				event_id: 0,
				current_url: encodeURIComponent(url),
				previous_url: encodeURIComponent(prevUrl),
				current_point_action: point.length > 0 ? encodeURIComponent(point) : undefined,
				previous_point_action: prevPoint.length > 0 ? encodeURIComponent(prevPoint) : undefined,
				search_text: pathIds.length > 0 ? encodeURIComponent(pathIds) : undefined,
				screentype: this.isDesktop ? 1 : 2,
			};
		},
		userNavigationSendToSlon: function (pointAction = '', pathIds = '') {
			let clearedCurr = clearURL(document.location.href);
			let clearedPrev = clearURL(this.prevUrl);
			let clearedPoint = clearURL(pointAction);
			let clearedPrevPoint = clearURL(this.prevPointAction);

			// Don't send duplicate click on same elements
			if ((clearedPrev !== clearedCurr) || (this.prevPathIds !== pathIds) || (clearedPoint !== clearedPrevPoint)) {
				let correctPrevUrl = (clearedPrev !== clearedCurr) ? clearedPrev : this.prevOldUrl;	// Send previousUrl differ from currentUrl
				let sendData = this.formDataEventUserNavigation(clearedCurr, correctPrevUrl, clearedPoint, clearedPrevPoint, pathIds);
				this._slonik(sendData);
				this.prevPointAction = clearedPoint;
				this.prevPathIds = pathIds;
				if (clearedPrev !== clearedCurr) {
					this.prevOldUrl = this.prevUrl;
					this.prevUrl = clearedCurr;
				}
			}
		},
		userNavigationClickMap: function (click) {
			if (this.trackTag.includes(click.target.tagName) || click.target.className?.includes('click-log')) {
				let pathById = formPathByIdClickElement(click.path);
				let pointAction = formDescriptionClickElement(click.target);

				// Don't send only tagName or emptyDescription or duplicate event by nested Tags
				if (!this.trackTag.includes(pointAction) && (this.prevEvent.x !== click.x) && (this.prevEvent.y !== click.y)) {
					this.userNavigationSendToSlon(pointAction, pathById);
					this.prevEvent.x = click.x;
					this.prevEvent.y = click.y;
				}
			}
		},
	},
};
