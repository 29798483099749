import { mapGetters, mapActions, mapMutations } from 'vuex';
import { SET_DIIA_STAGE_INDEX, SET_STOP_PING, SET_DIIA_TYPE_CHECK } from '../../../store/Common/modules/auth/modules/diia/mutations/mutations-types';
import translate from './i18n';

export default {
  name: 'AuthByDiiaPopup',
  components: {
    'common-pop-up': () => import('../../Common/Popup.vue'),
    'help-stage': () => import('./Stages/Help/index.vue'),
    'retry-stage': () => import('./Stages/Retry/index.vue'),
    'instruction-stage': () => import('./Stages/Instruction/index.vue'),
    'qr-code-stage': () => import('./Stages/QRCode/index.vue'),
    'wait-stage': () => import('./Stages/Wait/index.vue'),
    'shared-another-car': () => import('./Stages/SharedAnotherCar/index.vue'),
  },
  data() {
    const defaultTitle = this.$t('Підтвердіть особу через Дія.Підпис');
    return {
      isActive: false,
      titlePopup: defaultTitle,
      defaultTitle,
      queue: [
        { component: 'help-stage' },
        { component: 'instruction-stage' },
        { component: 'qr-code-stage' },
        { component: 'wait-stage' },
        { component: 'retry-stage' },
        { component: 'shared-another-car' },
      ],
    };
  },
  mounted() {
    if (this.isMobile) {
      if (Number(this.userData.userId) === 12724982) {
        this.fetchAuthDiiaLink('/cabinet/auth-diia?diia-token=:token');
      } else {
        this.fetchAuthDiiaLink('/cabinet?diia-token=:token');
      }
    }
  },
  computed: {
    ...mapGetters('Common/auth/diia', {
      index: 'index',
      advId: 'advId',
      statusConfirmedCar: 'statusConfirmedCar',
    }),
    ...mapGetters({
      userData: 'Common/userData',
    }),
    active() {
      return this.queue[this.index];
    },
    modalClass() {
      const qr = this.index ? 'qr' : '';
      return `authorized-through-diia ${qr}`;
    },
    isConfirmedCar() {
      return this.statusConfirmedCar && this.statusConfirmedCar.includes(Number(this.advId));
    },
  },
  methods: {
    ...mapActions('Common/auth/diia', {
      fetchAuthDiiaLink: 'fetchDiiaAuthLink',
      fetchConfirmationFromDiia: 'fetchConfirmationFromDiia',
    }),
    ...mapMutations('Common/auth/diia', {
      setIndex: SET_DIIA_STAGE_INDEX,
      setStopPing: SET_STOP_PING,
      setTypeCheck: SET_DIIA_TYPE_CHECK,
    }),
    next(value) {
      if (value) this.setTypeCheck(value);
      this.setIndex(this.index + 1);
    },
    back() {
      this.setStopPing(true);
      this.setIndex(this.index - 1);
    },
    close() {
      this.setIndex(0);
      this.$emit('close');
      this._slonik({
        event_id: 221,
        current_point_action: 'exit',
        previous_point_action: this.isConfirmedCar ? 'share_diia' : 'add_diia',
      })
    },
    updateTitle(newTitle) {
      this.titlePopup = newTitle || this.defaultTitle;
    },
  },
  i18n: {
    ...translate,
  },
};
