import translate from './i18n';
import {mapGetters, mapMutations} from 'vuex';
import {SET_DIIA_STAGE_INDEX} from "../../../../../store/Common/modules/auth/modules/diia/mutations/mutations-types";

export default {
  name: 'Retry',
  computed: {
    ...mapGetters('Common/auth/diia', {
      typeCheck: 'typeCheck',
    }),
  },
  methods: {
    ...mapMutations('Common/auth/diia', {
      setIndex: SET_DIIA_STAGE_INDEX,
    }),
    tryAgain() {
      this.setIndex(2);
    }
  },
  mounted() {
    this.$emit('updateTitle', this.typeCheck === 'owner' ? this.$t('Підтвердіть документи на авто через Дія.Підпис') : '');
  },
  i18n: {
    ...translate
  }
}
