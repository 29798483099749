import Vue from 'vue';
import _laxios from '../../_laxios';

const {cabinet: {userInfo} = {}} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		data: {},
		_auth: {},
		userHasAdvertisementsForPromo: false,
	}, userInfo),
	actions: {
		async checkAdvertisements({ commit }, { price, category }) {
			try {
				const { hasAdvertisements } = await _laxios
					.analyticCheckAdvertisements
					.request({ params: { price, category }, responseType: 'json' });
				commit('userHasAdvertisementsForPromo', hasAdvertisements);
			} catch(error) {
				console.error(error);
			}
		},
		userInfo({commit, rootGetters, getters}, Cookie) {
			let {'cabinet/userInfo/_auth': _auth} = rootGetters;
			let {'lang/id': langId} = rootGetters;
			let data = {
				headers: {}
			};
			let sendRequest = false;

			if (Cookie) {
				data.headers.Cookie = Cookie;
			}

			data.params = {
				langId: langId
			};

			if (_TARGET_ === 'client') {
				if (!Object.keys(getters.userInfo).length) {
					sendRequest = true;
				}
			} else {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios.userInfoCabinet
					.request(data)
					.then(payload => {
						commit('userInfo', payload);
						return payload;
					})
					.catch(e => {
                        console.error('/cabinet/api/user/information/', e.message);
					});
			}

		},
		_auth({commit, rootGetters}, Cookie) {
			let {'cabinet/userInfo/_auth': _auth} = rootGetters;
			let data = {
				headers: {}
			};

			if (Cookie) {
				data.headers.Cookie = Cookie;
			}

			return _laxios._authCabinet
				.request(data)
				.then((payload) => {
					commit('_auth', payload);
					return payload;
				})
				.catch(e => {
                    console.error('/cabinet/api/user/', e.message);
					return {};
				});

		}
	},
	mutations: {
		userHasAdvertisementsForPromo(state, payload) {
			Vue.set(state, 'userHasAdvertisementsForPromo', payload);
		},
		userInfo(state, payload) {
			Vue.set(state, 'data', payload);
		},
		_auth(state, payload) {
			Vue.set(state, '_auth', payload);
		}
	},
	getters: {
		userHasAdvertisementsForPromo({ userHasAdvertisementsForPromo }) {
			return userHasAdvertisementsForPromo;
		},
		userInfo({data}) {
			return data;
		},
		userData({data: {user} = {}}) {
			return user;
		},
		packagesData({data: {data, packages, containerData} = {}}) {
			return {data, packages, containerData};
		},
		_auth({_auth}) {
			return _auth;
		}
	}
};
