import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import HeadCabinet from './components/HeadCabinet/index.vue';
import LeftBar from './components/LeftBar/index.vue';
import DiscountModal from './components/Modals/Discount/index.vue';
import mobileAPP from './components/mobileAPP/index.vue';
import SvgSprite from './SvgSprite/index.vue';
import AuthByDiiaPopup from './AuthByDiiaPopup/index.vue';
import translate from './i18n';

const storeModule = require('../../store/cabinet');
const userInfoModule = require('../../store/cabinet/userInfo');
const { get, unset, deleted } = require('../../helpers/cookie');
const segmentParser = require('../../helpers/segmentParser');
const userNavigationLogger = require('../../mixins/userNavigationLogger');

const resetCookieName = 'resetLs';
const resetCookieValue = 'reset';
const sortBlocksCookieName = 'ab_lc_sort_blocks';
const sortBlocksCookieValue = '1';
const localStorageKey = 'ria_auth_ua_data';

export default {
  name: 'Cabinet',
  mixins: [userNavigationLogger],
  components: {
    'header-cabinet': HeadCabinet,
    'left-bar': LeftBar,
    'mobile-app': mobileAPP,
    'discount-modal': DiscountModal,
    'svg-sprite': SvgSprite,
    'auth-by-diia-popup': AuthByDiiaPopup,
  },
  data() {
    return {
      spinnerClass: '',
      appContentClass: '',
      hasTestCookie: false,
    };
  },
  computed: {
    ...mapGetters({
      langId: 'lang/id',
      tags: 'cabinet/tags/tags',
      segment: 'cabinet/segment',
      _auth: 'cabinet/userInfo/_auth',
      counters: 'cabinet/leftBar/counters',
      isWebView: 'shared/device/isWebView',
      userInfo: 'cabinet/userInfo/userData',
      visibleDiiaPopup: 'Common/auth/diia/getVisiblePopDiia',
      getProSellerPageData: 'proAccount/getProSellerPageData',
    }),
    activeAdv() {
      return (this.counters && this.counters.MyAdvertisementsPublished)
        ? this.counters.MyAdvertisementsPublished.count
        : 0;
    },
  },
  methods: {
    ...mapActions({
      setNewAccountStatus: 'cabinet/tags/tags',
      setUser: 'cabinet/userInfo/_auth',
      setPrevPointAction: 'others/setPrevPointAction',
      setGDPR: 'others/setGDPR',
      updateVisibleDiiaPopup: 'Common/auth/diia/updateVisibleDiiaPopup',
      getUserDataCommon: 'Common/userData',
    }),
    userData() {
      return this.getUserData.id || {};
    },
    formDataEventUserNavigation(url, prevUrl, point, prevPoint, pathIds = '') {
      this.setPrevPointAction(prevPoint.length > 0 ? encodeURIComponent(prevPoint) : undefined);
      return {
        current_url: encodeURIComponent(url),
        previous_url: encodeURIComponent(prevUrl),
        event_id: 221,
        lang_id: this.langId,
        version_type: 1,
        screentype: this.isDesktop ? 1 : 2,
        user_segment: segmentParser(this.segment),
        user_b2b: segmentParser(this.segment, true),
        user_action: this.getProSellerPageData?.active || 0,
        banner_number: this.getProSellerPageData?.stage || 0,
        active_proposal_count: this.activeAdv,
        current_point_action: point.length > 0 ? encodeURIComponent(point) : undefined,
        previous_point_action: prevPoint.length > 0 ? encodeURIComponent(prevPoint) : undefined,
        search_text: pathIds.length > 0 ? encodeURIComponent(pathIds) : undefined,
        a_b_test_version: get(sortBlocksCookieName) === sortBlocksCookieValue ? 2 : 1,
      };
    },
    emchEndEm({ scroll, spinner }) {
      if (spinner) {
        this.spinnerClass = 'spinner_lk';
      }
      if (scroll) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
    restoreAccount() {
      this.appContentClass = 'content_load';
      axios.get('/ph/mymenu/restoreaccount/').then((resp) => {
        if (resp.status === 200) {
          this.setNewAccountStatus({ noCache: true });
          this.appContentClass = '';
        }
      }).catch((e) => {
        console.error('/ph/mymenu/restoreaccount/', e.message);
      });
    },
    logout() {
      deleted('PSP_ID', '/', '.ria.com', '');
      deleted('PSP_ID', '/', '.ria.com', 'Secure; SameSite=None; Partitioned;');
      unset('PHPSESSID');
      unset('jwt');
      window.location = `/?exit=yes&sourceId=10&screentype=${this.isMobile ? 2 : 1}`;
    },
  },
  init({ store, context }) {
    store.registerModule('cabinet', storeModule);
    store.registerModule(['cabinet', 'userInfo'], userInfoModule, { runInNewContext: true });
    let cookies = {};
    if (context) {
      cookies = context.request.headers.cookie;
    }

    return Promise.all([
      store.dispatch('cabinet/userInfo/_auth', cookies).then((data) => {
        if (!data.userId) {
          context.response.redirect(`/login.html?from_url=${context.request.url}`);
        } else {
          store.dispatch('cabinet/tags/tags', { noCache: true, cookies });
        }
      }),
      store.dispatch('cabinet/loadSegment', cookies),
      store.dispatch('cabinet/promotions', cookies),
      store.dispatch('cabinet/getPromoFreePublication', cookies),
      store.dispatch('cabinet/getCards', cookies),
      store.dispatch('proAccount/fetchProSellerPage', cookies),
      store.dispatch('infoBlock/cabinet/fetchPopUpSlide', cookies),
    ]);
  },
  mounted() {
    try {
      if (get(resetCookieName) === resetCookieValue) {
        unset(resetCookieName);
        localStorage.setItem(localStorageKey, JSON.stringify({}));
      }
    } catch (e) {
      console.error('unset cookie and storage');
      console.error(e.message);
    }
    if (!Object.keys(this._auth).length && _TARGET_ === 'client') {
      // сетапимо юзера якщо сторінка відмалювалась на клієнті
      this.setUser();
    }

    this.$root.$on('appContentAddClass', (className) => {
      this.appContentClass = className;
    });

    // Видалити якшо банер для одеси з куки slide_FreeForOdesaRegion
    this.getUserDataCommon();
    this.setGDPR();
  },
  metaInfo() {
    return {
      title: 'AUTO.RIA™ — Особистий кабінет',
      meta: [
        { name: 'format-detection', content: 'telephone=no' },
        { name: 'description', content: '' },
        { name: 'keywords', content: '' },
        { property: 'og:title', content: '' },
        { property: 'og:description', content: '' },
        { name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW' },
      ],
      link: [
        { rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/' },
        { rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/' },
        { rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/' },
        { rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/' },
        { rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/' },
      ],
    };
  },
  i18n: {
    ...translate,
  },
};
