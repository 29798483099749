export default {
  messages: {
    uk: {
      'html.buAuto.personalArea': 'Особистий кабінет',
      'common.reestablish': 'Відновити',
      'html.buAuto.lk57': 'Ваш обліковий запис видалений і недоступний для перегляду. Вказані телефони і e-mail не можуть бути використані для інших акаунтів. Відновити свій обліковий запис ви можете в будь-який момент.',
      'html.buAuto.prodatavto': 'Продати авто',
      Вийти: 'Вийти',
      'Вимкнути PRO-акаунт': 'Вимкнути PRO-акаунт',
      'Статистика вашої компанії': 'Статистика вашої компанії',
    },
    ru: {
      'html.buAuto.personalArea': 'Личный кабинет',
      'common.reestablish': 'Восстановить',
      'html.buAuto.lk57': 'Ваша учетная запись удалена и не доступна для просмотра. Указанные телефоны и e-mail не могут быть использованы для других аккаунтов. Восстановить свою учетную запись вы можете в любой момент.',
      'html.buAuto.prodatavto': 'Продать авто',
      Вийти: 'Выйти',
      'Вимкнути PRO-акаунт': 'Выключить PRO-акаунт',
      'Статистика вашої компанії': 'Статистика вашей компании',
    },
  },
};
