export default {
    messages: {
        uk: {
            'Як авторизуватися через Дію': 'Як авторизуватися через Дію',
            'Підтвердіть особу через Дія.Підпис': 'Підтвердіть особу через Дія.Підпис',
            'Авторизований через Дію': 'Авторизований через Дію',
            'Увійти через Дію': 'Увійти через Дію',
            'Відскануйте QR-код сканером у застосунку Дія': 'Відскануйте QR-код сканером у застосунку Дія',
            'Активуйте Дія.Підпис': 'Активуйте Дія.Підпис',
            'Зробіть фото для перевірки': 'Зробіть фото для перевірки. Дотримуйтесь підсказок в нижній частині екрану.',
            'Придумайте код для Дія.Підпис': 'Придумайте код для Дія.Підпис та повторіть його',
            'Підтвердьте запит на авторизацію': 'Підтвердьте запит на авторизацію через Дія.Підпис',
        },
        ru: {
            'Як авторизуватися через Дію': 'Как авторизироваться через Дію',
            'Підтвердіть особу через Дія.Підпис': 'Подтвердите личность через Дія.Підпис',
            'Авторизований через Дію': 'Авторизованный через Дію',
            'Увійти через Дію': 'Войти через Дію',
            'Відскануйте QR-код сканером у застосунку Дія': 'Отсканируйте QR-код сканером в приложениии Дія',
            'Активуйте Дія.Підпис': 'Активируйте Дія.Підпис',
            'Зробіть фото для перевірки': 'Сделайте фото для проверок. Следите за подсказками в нижней части экрана.',
            'Придумайте код для Дія.Підпис': 'Придумайте код для Дія.Підпис и повторите его.',
            'Підтвердьте запит на авторизацію': 'Подтвердите запрос на авторизацию через Дія.Підпис',
        }
    }
}
