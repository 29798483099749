import { mapGetters, mapActions } from 'vuex';
import translate from './i18n';

export default {
  name: 'Wait',
  computed: {
    ...mapGetters('Common/auth/diia', {
      token: 'token',
    }),
  },
  methods: {
    ...mapActions('Common/auth/diia', {
      bindUser: 'bindUser',
      updateVisibleDiiaPopup: 'updateVisibleDiiaPopup',
    }),
  },
  async mounted() {
    try {
      const { binded } = await this.bindUser(this.token);
      const authDiiaFrom = this.$route.query?.authDiiaFrom || '';

      if (binded) {
        this._slonik({
          event_id: 221,
          current_point_action: 'exit',
          previous_point_action: 'autorithation',
        })
        if (authDiiaFrom) {
          window.location.href = authDiiaFrom;
        } else {
          this.updateVisibleDiiaPopup(false);
        }
      }
    } catch (error) {
      console.error('Error during mounted:', error);
    }
    this.$emit('updateTitle', this.typeCheck === 'owner' ? this.$t('Підтвердіть документи на авто через Дія.Підпис') : '');
  },
  i18n: {
    ...translate,
  },
};
