import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AuthByDiiaFlag',
  computed: {
    ...mapGetters('Common', {
      isConfirmAccountByDiia: 'isConfirmAccountByDiia',
    }),
  },
  methods: {
    ...mapActions({
      updateVisibleDiiaPopup: 'Common/updateVisibleDiiaPopup',
    }),
  },
  i18n: require('./i18n').default,
};
