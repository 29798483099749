export default {
  messages: {
    uk: {
      'Зачекайте декілька секунд,': 'Зачекайте декілька секунд,',
      'опрацьовуємо ваше підтвердження': 'опрацьовуємо ваше підтвердження',
      'Підтвердіть документи на авто через Дія.Підпис': 'Підтвердіть документи на авто через Дія.Підпис',
    },
    ru: {
      'Зачекайте декілька секунд,': 'Подождите несколько секунд,',
      'опрацьовуємо ваше підтвердження': 'прорабатываем ваше подтверждение',
      'Підтвердіть документи на авто через Дія.Підпис': 'Подтвердите документы на авто через Дія.Подпись',
    }
  }
}
