import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'HeadCabinet',
	components: {
		'auth-by-diia-flag': require('./components/AuthByDiiaFlag/index.vue').default,
	},
	data: function () {
		return {};
	},
	computed: {
		...mapGetters({
			userData: 'cabinet/userInfo/userData',
			balance: 'cabinet/balance/balance',
			segment: 'cabinet/segment',
		})
	},
	methods: {
		...mapActions({
			setBalance: 'cabinet/balance/balance',
		})
	},
	init({ store, context }) {
		let cookies = {};
		if (context) {
			cookies = context.request.headers.cookie;
		}
		return Promise.all([
			store.dispatch('cabinet/content/content', cookies),
			store.dispatch('cabinet/userInfo/userInfo', cookies),
			store.dispatch('cabinet/balance/balance', cookies),
		]);
	},
	mounted() {
		this.setBalance();
	},
	i18n: require('./i18n').default,
};
